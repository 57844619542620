import {AfterViewInit, Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  @Input() focusOnInit: boolean;
  constructor(
      private el: ElementRef
  ) { }

  ngAfterViewInit() {
    if (!this.focusOnInit)
      this.el.nativeElement.focus();
  }

}
