import {Routes} from '@angular/router';

import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';
import {OrgProfileComponent} from './modules/org-profile/org-profile.component';
import {AclGuardGuard} from './authentication/guard/acl-guard.guard';
import {UserProfileComponent} from "./modules/user-profile/user-profile.component";

export const Approutes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: 'module',
    component: FullComponent,
    children: [
      {
        path: '',
        loadChildren: './dashboards/dashboard.module#DashboardModule',
      },
      {
        path: 'org-profile',
        loadChildren: './modules/org-profile/org-profile.module#OrgProfileModule'
      },
      {
        path: 'sysConfig',
        loadChildren: './system-config/system-config.module#SystemConfigModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"sysConfig"}
      },
      {
        path: 'user-profile',
        component: UserProfileComponent
      },
      {
        path: 'labelConfig',
        loadChildren: './modules/label-config/label-config.module#LabelConfigModule'
      },
      {
        path: 'bom',
        loadChildren: './modules/bom/bom.module#BOMModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/bom"}
      },
      {
        path: 'packing',
        loadChildren: './modules/packing/packing.module#PackingModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/packing"}
      },
      {
        path: 'dashboard',
        loadChildren: './dashboards/dashboard.module#DashboardModule'
      },
      {
        path: 'ph',
        loadChildren: './modules/ph/ph.module#PhModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/ph"}
      },
      {
        path: 'brand',
        loadChildren: './modules/brand/brand.module#BrandModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/brand"}
      },
      {
        path: 'invoice-category',
        loadChildren: './modules/invoice-category/invoice-category.module#InvoiceCategoryModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/finishType"}
      },
      {
        path: 'currency-type',
        loadChildren: './modules/currency-type/currency-type.module#CurrencyTypeModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/currency"}
      },
      {
        path: 'gst',
        loadChildren: './modules/gst/gst.module#GstModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/gst"}
      },
      {
        path: 'retailcost',
        loadChildren: './modules/retail/retail.module#RetailModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/retailCost"}
      },
      {
        path: 'btbimg',
        loadChildren: './modules/btbimg/btbimg.module#BtbimgModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"btbPortal/getBySection"}
      },
      {
        path: 'orderbook',
        loadChildren: './modules/order-book-mange/order-book-mange.module#OrderBookMangeModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"orderBook/getAllCustomProduct"}
      },
      {
        path: 'basicOH',
        loadChildren: './modules/basic-oh/basic-oh.module#BasicOHModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/gst"}
      },
      {
        path: 'price-type',
        loadChildren: './modules/price-type/price-type.module#PriceTypeModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/priceType"}
      },
      {
        path: 'category-type',
        loadChildren: './modules/category-type/category-type.module#CategoryTypeModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/categoryType"}
      },
      {
        path: 'new-category',
        loadChildren: './modules/new-category/new-category.module#NewCategoryModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/categoryNew"}
      },
      {
        path: 'uom',
        loadChildren: './modules/uom/uom.module#UomModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/uom"}
      },
      {
        path: 'customer-type',
        loadChildren: './modules/customer-type/customer-type.module#CustomerTypeModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/customerType"}
      },
      {
        path: 'supporting-material',
        loadChildren: './modules/supporting-material/supporting-material.module#SupportingMaterialModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/supportingMaterial"}
      },
      {
        path: 'supporting-material-inward',
        loadChildren: './modules/supporting-material-inward/supporting-material-inward.module#SupportingMaterialInwardModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/inward"}
      },
      {
        path: 'supporting-material-allocation',
        loadChildren: './modules/supporting-material-allocation/supporting-material-allocation.module#SupportingMaterialAllocationModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/supportingMaterialAllocation"}
      },
      {
        path: 'process',
        loadChildren: './modules/process/process.module#ProcessModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/process"}
      },
      {
        path: 'product-group',
        loadChildren: './modules/product-group/product-group.module#ProductGroupModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/productGroup"}
      },
      {
        path: 'material-group',
        loadChildren: './modules/material-group/material-group.module#MaterialGroupModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/productGroup"}
      },
      {
        path: 'solubility',
        loadChildren: './modules/solubility/solubility.module#SolubilityModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/solubility"}
      },
      {
        path: 'stock-param4',
        loadChildren: './modules/stock-param/stock-param4/stock-param4.module#StockParam4Module',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/stockParam4"}
      },
      {
        path: 'stock-param5',
        loadChildren: './modules/stock-param/stock-param5/stock-param5.module#StockParam5Module',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/stockParam5"}
      },
      {
        path: 'stock-param6',
        loadChildren: './modules/stock-param/stock-param6/stock-param6.module#StockParam6Module',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/stockParam6"}
      },
      {
        path: 'stock-param7',
        loadChildren: './modules/stock-param/stock-param7/stock-param7.module#StockParam7Module',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/stockParam7"}
      },
      {
        path: 'stock-param8',
        loadChildren: './modules/stock-param/stock-param8/stock-param8.module#StockParam8Module',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/stockParam8"}
      },
      {
        path: 'stock-param9',
        loadChildren: './modules/stock-param/stock-param9/stock-param9.module#StockParam9Module',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/stockParam9"}
      },
      {
        path: 'customParameters',
        loadChildren: './modules/custom-parameters/custom-parameters.module#CustomParametersModule',
        canActivate: [AclGuardGuard],
        data: {"moduleName":"customParam"}
      },
      {
        path: 'sales-order',
        loadChildren: './modules/sales-order/sales-order.module#SalesOrderModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/salesOrder"}
      },
      {
        path: 'moisture',
        loadChildren: './modules/moisture/moisture.module#MoistureModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/moisture"}
      },
      {
        path: 'costHead',
        loadChildren: './modules/cost-head/cost-head.module#CostHeadModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/costHead"}
      },
      {
        path: 'purity',
        loadChildren: './modules/purity/purity.module#PurityModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/purity"}

      },
      {
        path: 'department',
        loadChildren: './modules/department/department.module#DepartmentModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/department"}
      },
      {
        path: 'zone',
        loadChildren: './modules/zone/zone.module#ZoneModule',
        canActivate: [AclGuardGuard],
        data:{"moduleName":"chemical/zone"}
      },
      {
        path: 'transport',
        loadChildren: './modules/transport/transport.module#TransportModule',
        canActivate: [AclGuardGuard],
        data:{"moduleName":"chemical/transport"}
      },
      {
        path: 'crmActivity',
        loadChildren: './modules/crm-activity/crm-activity.module#CrmActivityModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"crm/activity"}
      },
      {
        path: 'Planning-Scheduling',
        loadChildren: './modules/scheduling/scheduling.module#SchedulingModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/planning_scheduling"}
      },
      {
        path: 'scheduling',
        loadChildren: './modules/scheduling/scheduling.module#SchedulingModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/scheduling"}
      },
      {
        path: 'funnel',
        loadChildren: './modules/funnel/funnel.module#FunnelModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"crm/funnel"}
      },
      {
        path: 'quotation',
        loadChildren: './modules/quotation/quotation.module#QuotationModule',
        canActivate: [AclGuardGuard],
        data: {"moduleName":"crm/quotation"}
      },
      {
        path: 'funnelType',
        loadChildren: './modules/funnel-type/funnel-type.module#FunnelTypeModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/funnelType"}
      },
      {
        path: 'funnelStage',
        loadChildren: './modules/funnel-stage/funnel-stage.module#FunnelStageModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"crm/funnelStage"}
      },
      {
        path: 'party',
        loadChildren: './modules/party/party.module#PartyModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"party"}
      },
      {
        path: 'purchase-order',
        loadChildren: './modules/purchase-order/purchase-order.module#PurchaseOrderModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/purchaseOrder"}
      },
      {
        path: 'new-product',
        loadChildren: './modules/new-product/new-product.module#NewProductModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/newItem"}
      },
      {
        path: 'finish-item',
        loadChildren: './modules/finish-item/finish-item.module#FinishItemModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/finishItem"}
      },
      {
        path: 'category',
        loadChildren: './modules/category/category.module#CategoryModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"erp/category"}
      },
      {
        path: 'product',
        loadChildren: './modules/product/product.module#ProductModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/item"}
      },
      {
        path: 'packing-type-inward',
        loadChildren: './modules/packing-type-inward/packing-type-inward.module#PackingTypeInwardModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/packingTypeInward"}
      },
      {
        path: 'planning',
        loadChildren: './modules/planning/planning.module#PlanningModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/planning"}
      },
      {
        path: 'planning',
        loadChildren: './modules/planning/planning.module#PlanningModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/only_planning"}
      },
      {
        path: 'loom-vise-daily-production',
        loadChildren: './modules/loom-vise-daily-production-log/loom-vise-daily-production-log.module#LoomViseDailyProductionLogModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/loom-vise-daily-production-log"}
      },
      {
        path: 'productType',
        loadChildren: './modules/product-type/product-type.module#ProductTypeModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/productType"}
      },
      {
        path: 'containerType',
        loadChildren: './modules/container-type/container-type.module#ContainerTypeModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"Container"}
      },
      {
        path: 'production-batch',
        loadChildren: './modules/production-batch/production-batch.module#ProductionBatchModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/productionbatch"}
      },
      {
        path: 'weavingProcess',
        loadChildren: './modules/weaving-process/weaving-process.module#WeavingProcessModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/weavingProcess"}
      },
      {
        path: 'new-production-batch',
        loadChildren: './modules/new-production-batch/new-production-batch.module#NewProductionBatchModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/newproductionbatch"}
      },
      {
        path: 'daily-production',
        loadChildren: './modules/daily-production/daily-production.module#DailyProductionModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/dailyProduction"}
      },
      {
        path: 'inward',
        loadChildren: './modules/inward/inward.module#InwardModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/inward"}
      },
      {
        path: 'labTest',
        loadChildren: './modules/lab-test/lab-test.module#LabTestModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/labTest"}
      },
      {
        path: 'dispatch',
        loadChildren: './modules/dispatch/dispatch.module#DispatchModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/dispatch"}
      },
      {
        path: 'inward-foundary',
        loadChildren: './modules/inward-foundary/inward-foundary.module#InwardFoundaryModule',
      },
      {
        path: 'transferAndDispatch',
        loadChildren: './modules/transfer-and-dispatch/transfer-and-dispatch.module#TransferAndDispatchModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/transfer-and-dispatch"}
      },
      {
        path: 'pre-inward',
        loadChildren: './modules/pre-inward/pre-inward.module#PreInwardModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/preinward"}
      },
      {
        path: 'inward-stock',
        loadChildren: './modules/inward-stock/inward-stock.module#InwardStockModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/itemStock/stock"}
      },
      {
        path: 'materialRequest',
        loadChildren: './modules/material-request/material-request.module#MaterialRequestModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/requestApproval"}
      },
      {
        path: 'materialRequestConfig',
        loadChildren: './modules/material-request-config/material-request-config.module#MaterialRequestConfigModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/requestApprovalConfig"}
      },
      {
        path: 'message-templates',
        loadChildren: './modules/message-template/message-template.module#MessageTemplateModule',
        canActivate: [AclGuardGuard],
        data:{"moduleName":"chemical/smsTemplate"}
      },
      {
        path: 'part-code',
        loadChildren: './modules/part-code/part-code.module#PartCodeModule',
        canActivate: [AclGuardGuard],
        data:{"moduleName":"chemical/partCodeConfig"}
      },
      {
        path: 'user',
        loadChildren: './modules/user/user.module#UserModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"staff"}
      },
      {
        path: 'role',
        loadChildren: './modules/role/role.module#RoleModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"role"}
      },
      {
        path: 'aclConfig',
        loadChildren: './modules/acl-config/acl-config.module#AclConfigModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"acl"}
      },
      {
        path: 'reports',
        loadChildren: './modules/report/report.module#ReportModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/report"}
      },
      {
        path: 'machine',
        loadChildren: './modules/machine/machine.module#MachineModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/machine"}
      },
      {
        path: 'custom-field',
        loadChildren: './modules/custom-field/custom-field.module#CustomFieldModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"customField"}
      },
      {
        path: 'custom-module-field',
        loadChildren: './modules/custom-module-field/custom-module-field.module#CustomModuleFieldModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"moduleCustomField"}
      },
      {
        path: 'services',
        loadChildren: './modules/services/services.module#ServicesModule',
        // canActivate:[AclGuardGuard],
        // data:{"moduleName":"chemical/services"}
      },
      {
        path: 'moduleFieldConfig',
        loadChildren: './modules/module-param-config/module-param-config.module#ModuleParamConfigModule',
        canActivate:[AclGuardGuard],
        data:{"moduleName":"chemical/formConfig"}
      },{
      //RfqCreationVendorModule
        path: 'rfqCreation',
        loadChildren: './modules/rfq-creation-vendor/rfq-creation-vendor.module#RfqCreationVendorModule',
        //canActivate:[AclGuardGuard],
        //data:{"moduleName":"chemical/formConfig"}

      }, {
        path: 'rfqReceived',
        loadChildren: './modules/rfq-received/rfq-received.module#RfqReceivedModule',
        //chemical_angular/src/app/modules/rfq-received/rfq-received.module.ts
        //canActivate: [AclGuardGuard],
      },{
        path: 'rfqCompare',
        loadChildren: './modules/rfq-compare/rfq-compare.module#RfqCompareModule',
        //chemical_angular/src/app/modules/rfq-received/rfq-received.module.ts
        //canActivate: [AclGuardGuard],
      },
      {
        path: 'vendorRegistration',
        loadChildren: './modules/vendor-registration/vendor-registration.module#VendorRegistrationModule',
        // canActivate:[AclGuardGuard],
        // data:{"moduleName":"chemical/formConfig"}
      },
      {
        path: 'ecommerce',
        loadChildren: './modules/ecommerce/ecommerce.module#EcommerceModule',
        // canActivate:[AclGuardGuard],
        // data:{"moduleName":"chemical/formConfig"}
      },
    ]
  },
];
