import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input("addUpdateUrl") auUrl: string = "";
  @Input("title") title: string = "";
  @Input("addNewLabel") addNewLabel: string = "Add New";
  @Input("returnUrl") returnUrl: string = "";
  @Input("returnLabel") returnLabel : string = "Return";
  @Input("isCustomerManage") isCustomerManage : boolean;

  @Input("allocationUrl") allocationUrl : string = "";
  @Input("allocationLabel") allocationLabel : string = "Allocation";
  @Input("disableAllocation") disableAllocation : boolean = false;

  @Output("onRefresh") onRefreshCallback: EventEmitter<void> = new EventEmitter<void>();

  @Input("disableButtons") disable: boolean = false;
  @Input("disableReturn") disableReturn : boolean = false; 
  
  constructor(private router: Router) { }

  ngOnInit() {
  }

  onAddNewClick() {
    this.router.navigate([this.auUrl]);
  }
  onRefresh() {
    this.onRefreshCallback.emit();
  }
  onReturnClick() {
    this.router.navigate([this.returnUrl]);
  }

  onAllocationClick(){
    this.router.navigate([this.allocationUrl]);
  }

  onBack(){
    if(localStorage.getItem("customerId")){
      let id = localStorage.getItem("customerId");
      this.router.navigate(["module/party/addUpdate/"+ id]);
    }
    
  }
}
