import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserProfileModel} from "./model/user-profile.model";
import {UserProfileService} from "./service/user-profile.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  formGroup: FormGroup;
  public data: UserProfileModel = new UserProfileModel();
  public isUpdate: boolean;
  public submitted: boolean = false;

  constructor(private service: UserProfileService,
              private formBuilder: FormBuilder,
              private toaster: ToastrService) { }

  ngOnInit() {
    this.setValidation();
    this.getData();
  }

  setValidation() {
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  getData() {
    this.service.getProfile().subscribe((response) => {
      if(response.responseCode === 200) {
        if(response && response.data) {
          this.data = response.data;
          if(this.data.id >= 0) this.isUpdate = true;
        }else {
          this.isUpdate = false;
        }
      }
    })
  }

  get f() { return this.formGroup.controls; }

  onSubmit() {
    if(this.formGroup.invalid) return;
    this.service.saveProfile(this.data).subscribe((response) => {
      if(response.responseCode === 200) {
        this.toaster.success('Profile Saved Successfully', 'Success');
      }else {
        this.toaster.error('Failed To Save Profile', 'Error');
      }
    })
  }
}
