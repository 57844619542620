import {Component, OnInit} from '@angular/core';
import {LocalDataStorageService} from './core/service/local-data-storage.service';
import {LoginService} from './authentication/login/service/login.service';
import {Router} from '@angular/router';
import {SystemConfigService} from './system-config/service/system-config.service';
import {SystemConfigModel} from './system-config/model/system-config-model';
import {ResponseCode} from './core/constant/response-code';
import {ToastrService} from 'ngx-toastr';
import {ModuleParamGlobConfigService} from "./core/service/moduleFieldConfig/module-param-glob-config.service";
import {ModuleParamConfigServiceService} from "./modules/module-param-config/services/module-param-config-service.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  public systemData: Array<SystemConfigModel>;
  public layoutMode;

  constructor(private localDataStorageService: LocalDataStorageService,
              private loginService: LoginService,
              private router: Router,
              private moduleParamGlobConfigService : ModuleParamGlobConfigService,
              private moduleParamConfigService : ModuleParamConfigServiceService,
              private sysConfig: SystemConfigService,
              private titleService: Title,
              private toaster: ToastrService
              ) {
    this.layoutMode = this.sysConfig.getLayoutMode();
  }

  ngOnInit(): void {
    
    if (localStorage.getItem('token')) {
      this.getModuleParamConfig();
      this.setSysConfig();
    } else {
      if(this.layoutMode !== 'b2b'){
        this.router.navigate(["/"]);
      }
    }
    /*this.setTitle();*/
  }

  setSysConfig() {
    this.systemData = this.sysConfig.getData('sys_config');

    if (!this.systemData) {
      this.sysConfig.getSystemConifgData().subscribe((response) => {
        if (response.responseCode === ResponseCode.OK) {
          if (response && response.dataList && response.dataList.length !== 0) {
            this.sysConfig.setData('sys_config', response.dataList);
            this.setTitle();
          }
        } else {
          this.toaster.error(response.responseMessage, 'ERROR');
        }
      });
    }
  }


  async getModuleParamConfig(){
    if(!this.moduleParamGlobConfigService.isDataSet()){
      let responseData = await this.moduleParamConfigService.getAll(undefined,undefined).toPromise();
      if(responseData.responseCode == ResponseCode.OK){
        this.moduleParamGlobConfigService.setData(responseData.dataList);
      }
    }
  }

  public setTitle() {
    if (this.sysConfig.sysConfig.get('TITLE_NAME')) {
      this.titleService.setTitle( this.sysConfig.sysConfig.get('TITLE_NAME') );
    }
  }
}
