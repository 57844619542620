import {Component, AfterViewInit, OnInit, Input} from '@angular/core';
import { CHEMICALCHROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ACLService } from '../AclService/acl.service';
import { AclDataModal } from './acl-data-modal';
import { environment } from '../../../environments/environment';
import { UserModal } from '../../modules/user/modal/user-modal';
import { AclConfigModal } from '../../modules/acl-config/modal/acl-config-modal';
import {VersionService} from "../service/version.service";

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showMenu  : string= '';
  showSubMenu : string = '';
  showSubSubMenu = '';
  versionJson : {version : string};
  version : string;

  @Input("isMiniMenu")
  isMiniMenu : boolean = false;

  private readonly MODE_CHEMICAL: number = 4;
  private readonly MODE_FOUNDRY: number = 1;

  public sidebarnavItems: Array<RouteInfo>;

  public loggedinUser: UserModal;
  public searchText: '';

  public allowModules: Map<string, AclDataModal>;
  // this is for the open close
    public collapseClass = '';
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    // console.log(element);
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
  addActiveClass1(element: any) {
    // console.log(element);
    if (element === this.showSubSubMenu) {
      this.showSubSubMenu = '0';
    } else {
      this.showSubSubMenu = element;
    }
  }
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private aclService: ACLService,
    private versionService : VersionService,
    private route: ActivatedRoute
  ) { }

  // End open close
    isMasterOpen: boolean = false;

  ngOnInit() {
    this.initLoggedUser();
    this.versionService.getVersion().subscribe(
        (response) =>{
          console.log("Version Data",response);
          this.versionJson = response;
        }
    );
  }

  initLoggedUser() {
    this.aclService.getLoggedInUser().subscribe(
      (response) => {
        this.loggedinUser = response.data;
        const activeUser = JSON.parse(JSON.stringify(response.data));
        delete activeUser.password;
        if (activeUser) {
            localStorage.setItem('activeUser', JSON.stringify(activeUser));
        }
        this.initMenu();
      }
    );
  }

  initMenu() {
    this.aclService.getAclData(this.loggedinUser.role.roleid).subscribe(
      (response) => {
        this.allowModules = new Map<string, AclDataModal>();
        response.dataList.forEach(
          (aclData) => {
            this.allowModules.set(aclData.moduleAPI.replace('/api/v1/', ''), aclData);
          }
        );
        // console.log(this.allowModules);
        const moduleStr: string = JSON.stringify(Array.from(this.allowModules.entries()));
        localStorage.setItem(environment.ACL_LOCAL_KEY, moduleStr);

        this.sidebarnavItems = new Array<RouteInfo>();
        let ROUTES = [];
        ROUTES = CHEMICALCHROUTES;
        ROUTES.forEach(
          (rData) => {
            if ((!rData.submenu || !rData.submenu.length || rData.submenu.length === 0) && this.isModuleAllow(rData.moduleName)) {
              this.sidebarnavItems.push(rData);
            } else {

              if (rData.submenu && rData.submenu.length >= 1) {
                const subMenuData: Array<RouteInfo> = new Array<RouteInfo>();
                rData.submenu.forEach(
                  (sData) => {
                    if ((!sData.submenu || !sData.submenu.length || sData.submenu.length === 0) && this.isModuleAllow(sData.moduleName)) {
                        subMenuData.push(sData);
                    } else {
                      if (sData.submenu && sData.submenu.length >= 1) {
                        const subSubMenuData: Array<RouteInfo> = new Array<RouteInfo>();
                        sData.submenu.forEach(ssData => {
                          if (this.isModuleAllow(ssData.moduleName)) {
                            subSubMenuData.push(ssData);
                          }
                        });
                        if (subSubMenuData && subSubMenuData.length > 0) {
                          sData.submenu = subSubMenuData;
                          subMenuData.push(sData);
                        }
                      }
                    }
                  });
                if (subMenuData && subMenuData.length > 0) {
                  rData.submenu = subMenuData;
                  this.sidebarnavItems.push(rData);
                }
              }
            }
          }
        );
        // console.log(JSON.stringify(this.sidebarnavItems));
      }
    );
  }

  isModuleAllow(moduleName: string): boolean {
    if (!moduleName || moduleName.trim().length === 0) {
      return true;
    }
    if (!this.allowModules.has(moduleName)) {
      return false;
    }
    const aclData: AclConfigModal = this.allowModules.get(moduleName);
    // if(environment.mode.includes(aclData.mode)){
    return aclData.view === 1;
    // }
  }

  onLogoutClick() {
    localStorage.clear();
    this.router.navigate(['/']);
  }


  onMenuClick(menuName : string,parentMenu : string) {
      this.collapseClass = 'collapse';

      if(parentMenu == this.showMenu && (!menuName || menuName.length == 0)){
          this.showMenu = "";
          this.showSubMenu = menuName;
          return;
      }
      this.showMenu = parentMenu;
      this.showSubMenu = menuName;
  }
}
