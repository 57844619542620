import { RouteInfo } from './sidebar.metadata';
import {ModuleParamGlobConfigService} from "../../core/service/moduleFieldConfig/module-param-glob-config.service";

/*
moduleName :
    desc  : ACL use
    value : API url after /api/v1/.
    expl  : url  "http://demoao.neofruition.co.in:83/api/v1/chemical/product" => "chemical/product"
*/

let moduleParam = new ModuleParamGlobConfigService();
console.log('side menu', moduleParam);

export const CHEMICAL_ROUTES_LIST: RouteInfo[] = [
        {
          path: '/module/dashboard',
          title: 'Dashboard',
          titleKey: 'Dashboard',
          icon: 'mdi mdi-view-dashboard',
          class: '',
          moduleName: '',
          extralink: false,
          submenu: [],
          imgUrl : "assets/images/dashboard-icon.png",
        },
        {
          path: '',
          title: 'Masters',
          titleKey: 'Masters',
          icon: 'mdi mdi-bookmark-plus-outline',
          class: 'has-arrow',
          moduleName: '',
          imgUrl : "assets/images/masters-icon.png",
          extralink: false,
          submenu: [
            {
              path: '/module/new-product/standardProductList',
              title: 'Standard Product',
              titleKey: 'Standard Product',
              moduleName: 'chemical/newItem',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/new-product/modifiedProductList',
              title: 'Modified Product',
              titleKey: 'Modified Product',
              moduleName: 'chemical/newItem',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/new-product/customProductList',
              title: 'Sample Product',
              titleKey: 'sampleProduct',
              moduleName: 'chemical/newItem',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/uom',
              title: 'UOM',
              imgUrl : "",
              titleKey: 'UOM',
              moduleName: 'erp/uom',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/product',
              title: 'Materials',
              titleKey: 'Materials',
              moduleName: 'chemical/item',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/purity',
              title: 'Sub Material',
              titleKey: 'Sub Material',
              moduleName: 'chemical/purity',
              icon: 'fas fa-vial',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/costHead',
              title: 'Labour Costs',
              titleKey: 'Labour Costs',
              moduleName: 'chemical/costHead',
              icon: 'mdi mdi-cube-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/finish-item',
              title: 'Finishes',
              titleKey: 'Finishes',
              moduleName: 'chemical/finishItem',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/basicOH',
              title: 'Basic OH',
              titleKey: 'Basic OH',
              moduleName: 'erp/basicOH',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/gst',
              title: 'GST',
              titleKey: 'GST',
              moduleName: 'erp/gst',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/new-category',
              title: 'Web Category',
              titleKey: 'Web Category',
              moduleName: 'erp/categoryNew',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/party',
              title: 'Customer',
              titleKey: 'Customer',
              moduleName: 'party',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/customer-type',
              title: 'Customer Type',
              titleKey: 'CustomerType',
              moduleName: 'erp/customerType',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/price-type',
              title: 'Price Type',
              titleKey: 'price type',
              moduleName: 'erp/priceType',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/currency-type',
              title: 'Currency',
              titleKey: 'currency',
              moduleName: 'erp/currency',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/productType',
              title: 'Material Type',
              titleKey: 'MaterialType',
              moduleName: 'chemical/productType',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/containerType',
              title: 'Container Type',
              titleKey: 'ContainerType',
              moduleName: 'Container',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/brand',
              title: 'Brand',
              titleKey: 'Brand',
              moduleName: 'erp/brand',
              icon: 'mdi mdi-factory',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/department',
              title: 'Departments',
              titleKey: 'Departments',
              imgUrl : "",
              moduleName: 'chemical/department',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/invoice-category',
              title: 'Invoice Category',
              titleKey: 'Invoice Category',
              moduleName: 'erp/finishType',
              icon: 'mdi mdi-factory',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/product-group',
              title: 'Product Group',
              titleKey: 'product Group',
              imgUrl : "",
              moduleName: 'erp/productGroup',
              icon: 'mdi mdi-poll-box',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/material-group',
              title: 'Material Group',
              titleKey: 'Material Group',
              imgUrl : "",
              moduleName: 'erp/material',
              icon: 'mdi mdi-poll-box',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/retailcost',
              title: 'Retail Cost',
              titleKey: 'retail',
              moduleName: 'erp/retailCost',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/btbimg',
              title: 'B2B Images',
              titleKey: 'btbimg',
              moduleName: 'btbPortal/getBySection',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/bom',
              title: 'BOM',
              titleKey: 'bom',
              moduleName: 'chemical/bom',
              icon: 'mdi mdi-basket-fill',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/category-type',
              title: 'Category Type',
              titleKey: 'Category type',
              moduleName: 'erp/categoryType',
              icon: 'mdi mdi-package-variant',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/supporting-material',
              title: 'Supporting Material',
              imgUrl : "",
              titleKey: 'Supporting Material',
              moduleName: 'chemical/supportingMaterial',
              icon: 'mdi mdi-account-box-outline',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/process',
              title: 'Process',
              titleKey: 'Process',
              imgUrl : "",
              moduleName: 'chemical/process',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/zone',
              title: 'Zone',
              titleKey: 'Zone',
              imgUrl : "",
              moduleName: 'zone',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/machine',
              title: 'Machine',
              titleKey: 'Machine',
              imgUrl : "",
              moduleName: 'chemical/machine',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/packing',
              title: 'Packing',
              titleKey: 'Packing',
              imgUrl : "",
              moduleName: 'chemical/packing',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/transport',
              title: 'Transport',
              titleKey: 'Transport',
              moduleName: 'chemical/transport',
              icon: 'mdi mdi-truck',
              class: '',
              imgUrl : "",
              extralink: false,
              submenu: []
            },
            {
              path: '/module/custom-field',
              title: 'Custom Field',
              titleKey: 'Custom Field',
              imgUrl : "",
              moduleName: 'customField',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/customParameters',
              title: 'Quality Parameters',
              titleKey: 'Quality Parameters',
              imgUrl : "",
              moduleName: 'customParam',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/custom-module-field',
              title: 'Custom Module Field',
              imgUrl : "",
              titleKey: 'Custom Module Field',
              moduleName: 'moduleCustomField',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/funnelStage',
              title: 'Funnel Stage',
              titleKey: 'Funnel Stage',
              imgUrl : "",
              moduleName: 'crm/funnelStage',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/crmActivity',
              title: 'CRM Activity',
              titleKey: 'CRM Activity',
              imgUrl : "",
              moduleName: 'crm/activity',
              icon: 'mdi mdi-settings',
              class: '',
              extralink: false,
              submenu: []
            },
          ]
        },
        {
          path: '',
          title: 'CRM',
          titleKey: 'CRM',
          icon: 'mdi mdi-ticket-account',
          class: 'has-arrow',
          moduleName: '',
          imgUrl : "assets/images/dashboard-icon.png",
          extralink: false,
          submenu: [
            {
              path: '/module/funnel',
              title: 'Funnel',
              titleKey: 'Funnel',
              icon: 'fa fa-filter',
              imgUrl : "",
              moduleName: 'crm/funnel',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/quotation',
              title: 'Quotation',
              titleKey: 'quotation',
              imgUrl : "",
              icon: 'mdi mdi-cart-outline',
              moduleName: 'crm/quotation',
              class: '',
              extralink: false,
              submenu: []
            }
          ]
        },
        {
          path: '/module/reports',
          title: 'Report',
          titleKey: 'Report',
          imgUrl : "assets/images/reports-icon.png",
          icon: 'mdi mdi-file-chart',
          class: '',
          moduleName: 'chemical/report',
          extralink: false,
          submenu: []
        },
        {
          path: '',
          title: 'Account',
          titleKey: 'Account',
          icon: 'mdi mdi-ticket-account',
          class: 'has-arrow',
          imgUrl : "assets/images/account-icon.png",
          moduleName: '',
          extralink: false,
          submenu: [

            {
              path: '/module/rfqCreation',
              title: 'RFQ Management',
              titleKey: 'RFQ Management',
              imgUrl : "",
              icon: 'mdi mdi-truck',
              moduleName: 'chemical/purchaseOrder',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/purchase-order',
              title: 'Purchase Order',
              titleKey: 'Purchase Order',
              imgUrl : "",
              icon: 'mdi mdi-truck',
              moduleName: 'chemical/purchaseOrder',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/sales-order',
              title: 'Sales Order',
              imgUrl : "",
              titleKey: 'Sales Order',
              icon: 'mdi mdi-cart-outline',
              moduleName: 'chemical/salesOrder',
              class: '',
              extralink: false,
              submenu: []
            }
          ]
        },
        {
          path: '',
          title: 'Inventory',
          titleKey: 'Inventory',
          icon: 'mdi mdi-factory',
          imgUrl : "assets/images/inventory-icon.png",
          class: 'has-arrow',
          moduleName: '',
          extralink: false,
          submenu: [
            {
              path: '/module/inward-stock',
              title: 'Material Stock',
              titleKey: 'Material Stock',
              icon: 'fa fa-retweet',
              imgUrl : "",
              moduleName: 'chemical/itemStock/stock',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/supporting-material-inward',
              title: 'Supporting Material Inward',
              titleKey: 'Supporting Material Inward',
              icon: 'mdi mdi-beaker',
              imgUrl : "",
              moduleName: 'chemical/inward',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/packing-type-inward',
              title: 'Packing Type Inward',
              titleKey: 'Packing Type Inward',
              icon: 'mdi mdi-beaker',
              imgUrl : "",
              moduleName: 'chemical/packingTypeInward',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/materialRequest',
              title: 'Material Request',
              titleKey: 'Material Request',
              icon: 'fa fa-request',
              imgUrl : "",
              moduleName: 'chemical/requestApproval',
              class: '',
              extralink: false,
              submenu: []
            }
          ]
        },
        {
          path: '/module/labTest',
          imgUrl : "assets/images/labtest-icon.png",
          title: 'Lab Test',
          titleKey: 'LabTest',
          icon: 'mdi mdi-bookmark-plus-outline',
          class: '',
          moduleName: 'chemical/labTest',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/Planning-Scheduling',
          imgUrl : "assets/images/masters-icon.png",
          title: 'Planning & Scheduling',
          titleKey: 'Planning & Scheduling',
          icon: 'mdi mdi-chip',
          class: '',
          moduleName: 'chemical/planning_scheduling',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/planning',
          imgUrl : "assets/images/masters-icon.png",
          title: 'Planning',
          titleKey: 'Planning',
          icon: 'mdi mdi-calendar',
          class: '',
          moduleName: 'chemical/only_planning',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/production-batch',
          imgUrl : "assets/images/productionbatch-icon.png",
          title: 'Production Batch',
          titleKey: 'ProductionBatch',
          icon: 'fa fa-cogs',
          class: '',
          moduleName: 'chemical/productionbatch',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/new-production-batch',
          imgUrl : "assets/images/productionbatch-icon.png",
          title: 'Production Batch',
          titleKey: 'NewProductionBatch',
          icon: 'fa fa-cogs',
          class: '',
          moduleName: 'chemical/newproductionbatch',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/weavingProcess',
          imgUrl : "assets/images/productionbatch-icon.png",
          title: 'Weaving Process',
          titleKey: 'weavingProcess',
          icon: 'fa fa-cogs',
          class: '',
          moduleName: 'chemical/weavingProcess',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/daily-production',
          imgUrl : "assets/images/productionbatch-icon.png",
          title: 'Daily Production',
          titleKey: 'DailyProduction',
          icon: 'fa fa-clock-o',
          class: '',
          moduleName: 'chemical/dailyProduction',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/loom-vise-daily-production',
          imgUrl : "assets/images/productionbatch-icon.png",
          title: 'DP Log',
          titleKey: 'DailyProductionLog',
          icon: 'fa fa-file-text',
          class: '',
          moduleName: 'chemical/loom-vise-daily-production-log',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/dispatch',
          imgUrl : "assets/images/dispatch-icon.png",
          title: 'Dispatch',
          titleKey: 'Dispatch',
          icon: 'mdi mdi-truck',
          class: '',
          moduleName: 'chemical/salesOrder',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/transferAndDispatch',
          imgUrl : "assets/images/labtest-icon.png",
          title: 'Job Work',
          titleKey: 'jobWork',
          icon: 'fa fa-cogs',
          class: '',
          moduleName: 'chemical/transfer-and-dispatch',
          extralink: false,
          submenu: []
        },
        {
          path: '/module/user',
          imgUrl : "assets/images/staff-icon.png",
          title: 'Staff',
          titleKey: 'Staff',
          icon: 'mdi mdi-account',
          moduleName: 'staff',
          class: '',
          extralink: false,
          submenu: []
        },
        {
          path: '',
          imgUrl : "assets/images/settings-icon.png",
          title: 'Settings',
          titleKey: 'Settings',
          icon: 'fa fa-link',
          moduleName: '',
          class: '',
          extralink: false,
          submenu: [
            {
              path: '/module/message-templates',
              title: 'Data Exchange',
              titleKey: 'Data Exchange',
              icon: 'fa fa-retweet',
              imgUrl : "",
              moduleName: 'chemical/smsTemplate',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/message-templates',
              title: 'Message Templates',
              titleKey: 'Message Templates',
              icon: 'fa fa-retweet',
              imgUrl : "",
              moduleName: 'chemical/smsTemplate',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/part-code',
              title: 'Product Code',
              titleKey: 'Product Code',
              icon: 'fa fa-retweet',
              imgUrl : "",
              moduleName: 'chemical/partCodeConfig',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/materialRequestConfig',
              title: 'Material Request Configuration',
              titleKey: 'Material Request Configuration',
              icon: 'fa fa-retweet',
              imgUrl : "",
              moduleName: 'chemical/requestApprovalConfig',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/moduleFieldConfig',
              title: 'Module Param Config',
              titleKey: 'Module Param Config',
              icon: 'fa fa-retweet',
              imgUrl : "",
              moduleName: 'chemical/formConfig',
              class: '',
              extralink: false,
              submenu: []
            },
            {
              path: '/module/sysConfig',
              title: 'System Configuration',
              titleKey: 'System Configuration',
              icon: 'fa fa-setting',
              imgUrl : "",
              moduleName: 'sysConfig',
              class: '',
              extralink: false,
              submenu: []
            },
          ]
        },
        {
          path: '/module/aclConfig',
          imgUrl : "assets/images/ACLconfig-icon.png",
          title: 'ACL Config',
          titleKey: 'ACL Config',
          icon: 'mdi mdi-account',
          moduleName: 'acl',
          class: '',
          extralink: false,
          submenu: []
        }
      ];
