import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthUtilsService } from "../../core/util/auth-utils.service";
import { Observable } from "rxjs";
import { ResponseData } from "../../core/modal/base-save-update-response";
import { environment } from "../../../environments/environment";
import { PreInwardModal } from "../../modules/pre-inward/modal/pre-inward-modal";
import { PartyModal } from "../../modules/party/modal/party-modal";
import { PurchaseOrderModal } from "../../modules/purchase-order/modal/purchase-order";
import { SalesOrderModal } from "../../modules/sales-order/modal/sales-order-modal";
import { PHModal } from "../../modules/ph/modal/phmodal";
import { MoistureModal } from "../../modules/moisture/modal/moisture-modal";
import { SolubilityModal } from "../../modules/solubility/modal/solubility-modal";
import { Puritymodal } from "../../modules/purity/modal/puritymodal";
import { ProductModal } from "../../modules/product/modal/product-modal";
import { ProcessModel } from "../../modules/process/model/process.model";
import { CostHeadModel } from "../../modules/cost-head/model/cost-head.model";
import { DispatchDetailsModal } from "../../modules/dispatch/modal/dispatch-details-modal";
import { SupportingMaterial } from "../../modules/supporting-material/modal/supporting-material";
import { DepartmentModal } from "../../modules/department/modal/department-modal";
import { MachineModal } from "../../modules/machine/modal/machine-modal";
import { OrgProfileModal } from 'src/app/modules/org-profile/modal/org-profile-modal';
import { CustomModuleField } from "../../modules/custom-module-field/model/custom-module-field";
import { ProductTypeModel } from "../../modules/product-type/model/product-type.model";
import { ServicesModal } from "../../modules/services/modal/services-modal";
import { CrmActivity } from '../../modules/crm-activity/modal/crm-activity';
import { UserModal } from '../../modules/user/modal/user-modal';
import { FunnelStageModel } from '../../modules/funnel-stage/model/funnel-stage-model';
import { FunnelModal } from '../../modules/funnel/modal/funnel-modal';
import { CustomFieldModel } from '../../modules/custom-field/modal/custom-field-model';
import { map } from 'rxjs/operators';
import { TransportModel } from '../../modules/transport/model/transport-model';
import { CustomParameterModel } from "../../modules/custom-parameters/models/custom-parameter.model";
import { PartCodeModel } from '../../modules/part-code/model/part-code-model';
import { VendorCodeRequestResponseModel } from "../../modules/inward/modal/vendor-code-request-response.model";
import { PartyWiseVendorCodeModel } from "../../modules/inward/modal/party-wise-vendor-code.model";
import { PackingModel } from '../../modules/packing/model/packing-model';
import { ActivityLogReportModel } from 'src/app/modules/report/modal/AuditLogReport/activity-log-report-model';
import { PlanningModel } from "../../modules/planning/model/planning.model";
import { LabTestModel } from "../../modules/lab-test/model/lab-test.model";
import { CategoryModel } from "../../modules/category/model/category-model";
import { ProductGroupModel } from "../../modules/product-group/model/product-group-model";
import { NewProductModel } from "../../modules/new-product/model/new-product-model";
import { FinishItemModel } from "../../modules/finish-item/model/finish-item-model";
import { FinishTypeModel } from "../../modules/finish-item/model/finish-type-model";
import { BrandModel } from "../../modules/brand/model/brand-model";
import { CurrencyTypeModel } from 'src/app/modules/currency-type/model/currency-type-model';
import { PriceTypeModel } from 'src/app/modules/price-type/model/price-type-model';
import { NewCategoryModel } from 'src/app/modules/new-category/model/new-category-model';
import { CategoryTypeModel } from 'src/app/modules/category-type/model/category-type-model';
import {UomModel} from "../../modules/uom/model/uom-model";
import {BasicOHModel} from "../../modules/basic-oh/model/basic-ohmodel";
import {GstModel} from "../../modules/gst/model/gst-model";
import {CustomerTypeModel} from "../../modules/customer-type/model/customer-type-model";
import {RetailModel} from "../../modules/retail/model/retail-model";
import { ProductCategory } from 'src/app/modules/product/modal/product-category';
import { ProductClassification } from 'src/app/modules/new-product/model/product-classification';
import { idText } from 'typescript';

@Injectable({
  providedIn: 'root'
})
export class CommonSelectListService {

  public getAllByPartner = '/getAllByPartner';
  public getProductTypeByPartner = '/getProductTypeByPartner'
  public sortAlphabetically = (a, b) => {
    const textA = (a.name ? a.name.toUpperCase() : '');
    const textB = (b.name ? b.name.toUpperCase() : '');
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }

  constructor(
    private http: HttpClient,
    private authService: AuthUtilsService,
  ) { }

  public getPreInwardDetail(): Observable<ResponseData<PreInwardModal>> {
    return this.http.get<ResponseData<PreInwardModal>>(environment.SERVER_URL + 'chemical/preinward' + this.getAllByPartner)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: PreInwardModal[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = (a.slipNumber ? a.slipNumber.toUpperCase() : '');
              const textB = (b.slipNumber ? b.slipNumber.toUpperCase() : '');
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  public getPartyList(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>(environment.SERVER_URL + 'party' + this.getAllByPartner)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = (a.companyName ? a.companyName.toUpperCase() : '');
              const textB = (b.companyName ? b.companyName.toUpperCase() : '');
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  public getPoList(): Observable<ResponseData<PurchaseOrderModal>> {
    return this.http.get<ResponseData<PurchaseOrderModal>>(environment.SERVER_URL + 'chemical/purchaseOrder' + this.getAllByPartner);
  }

  public getSoList(): Observable<ResponseData<SalesOrderModal>> {
    return this.http.get<ResponseData<SalesOrderModal>>(environment.SERVER_URL + 'chemical/salesOrder' + this.getAllByPartner);
  }

  public getPhList(): Observable<ResponseData<PHModal>> {
    return this.http.get<ResponseData<PHModal>>(environment.SERVER_URL + 'chemical/ph' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getMoistureList(): Observable<ResponseData<MoistureModal>> {
    return this.http.get<ResponseData<MoistureModal>>(environment.SERVER_URL + 'chemical/moisture' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getSolubility(): Observable<ResponseData<SolubilityModal>> {
    return this.http.get<ResponseData<SolubilityModal>>(environment.SERVER_URL + 'chemical/solubility' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getPurityList(): Observable<ResponseData<Puritymodal>> {
    return this.http.get<ResponseData<Puritymodal>>(environment.SERVER_URL + 'chemical/purity' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getProductList(): Observable<ResponseData<ProductModal>> {
    return this.http.get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/item' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }
  public getAllProductTypeList(): Observable<ResponseData<ProductModal>> {
    return this.http.get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/item/getAllEntity')
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }
  public getSearchList(): Observable<ResponseData<NewProductModel>> {
    return this.http.get<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom/getAllBaseProduct')
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getProcessList(): Observable<ResponseData<ProcessModel>> {
    return this.http.get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/process' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getCostHeadList(): Observable<ResponseData<CostHeadModel>> {
    return this.http.get<ResponseData<CostHeadModel>>(environment.SERVER_URL + 'chemical/costHead' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getProduct(): Observable<ResponseData<DispatchDetailsModal>> {
    return this.http.get<ResponseData<DispatchDetailsModal>>(environment.SERVER_URL + 'chemical/itemStock')
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: DispatchDetailsModal[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = (a.item ? a.item.name.toUpperCase() : '');
              const textB = (b.item ? b.item.name.toUpperCase() : '');
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  public getMainProductTypeList(): Observable<ResponseData<ProductTypeModel>> {
    return this.http.get<ResponseData<ProductTypeModel>>(environment.SERVER_URL + 'chemical/productType' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getProductTypeList(): Observable<ResponseData<ProductTypeModel>> {
    return this.http.get<ResponseData<ProductTypeModel>>(environment.SERVER_URL + 'chemical/productType' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getProductTypeListFilter(): Observable<ResponseData<ProductTypeModel>> {
    return this.http.get<ResponseData<ProductTypeModel>>(environment.SERVER_URL + 'chemical/productType' + this.getProductTypeByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getSupportingMaterialList(): Observable<ResponseData<SupportingMaterial>> {
    return this.http.get<ResponseData<SupportingMaterial>>(environment.SERVER_URL + 'chemical/supportingMaterial' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getSupplierList(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>(environment.SERVER_URL + 'party/byType/2')
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = (a.companyName ? a.companyName.toUpperCase() : '');
              const textB = (b.companyName ? b.companyName.toUpperCase() : '');
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  public getCustomerList(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>(environment.SERVER_URL + 'party/byType/' + 1)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = (a.companyName ? a.companyName.toUpperCase() : '');
              const textB = (b.companyName ? b.companyName.toUpperCase() : '');
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  public getDepartmentList(): Observable<ResponseData<DepartmentModal>> {
    return this.http.get<ResponseData<DepartmentModal>>(environment.SERVER_URL + 'chemical/department' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getMachineList(): Observable<ResponseData<MachineModal>> {
    return this.http.get<ResponseData<MachineModal>>(environment.SERVER_URL + 'chemical/machine' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getOrganizationData(): Observable<ResponseData<OrgProfileModal>> {
    return this.http.get<ResponseData<OrgProfileModal>>(environment.SERVER_URL + 'organization' + this.getAllByPartner);
  }

  public getDynamicFieldConfig(reqObj): Observable<ResponseData<CustomModuleField>> {
    return this.http.post<ResponseData<CustomModuleField>>(environment.SERVER_URL + 'moduleCustomField/getCustomFields', reqObj);
  }
  public getProductAssosiatedWithComponent(id):Observable<ResponseData<any>>{
    console.log("Service ID::",id)
    return this.http.get<ResponseData<any>>(environment.SERVER_URL + 'erp/productbom/getProductBomByComponent/'+ id);
  }
  public getServicesList(): Observable<ResponseData<ServicesModal>> {
    return this.http.get<ResponseData<ServicesModal>>(environment.SERVER_URL + 'chemical/services' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  public getBOMList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(environment.SERVER_URL + 'chemical/bom' + this.getAllByPartner);
  }

  public getCrmActivityList(): Observable<ResponseData<CrmActivity>> {
    return this.http.get<ResponseData<CrmActivity>>(environment.SERVER_URL + 'crm/activity' + this.getAllByPartner);
  }

  public getStaffList(): Observable<ResponseData<UserModal>> {
    return this.http.get<ResponseData<UserModal>>(environment.SERVER_URL + 'staff' + this.getAllByPartner)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            response.dataList = response.dataList.sort((a, b) => {
              const textA = (a.firstname ? a.firstname.toUpperCase() : '');
              const textB = (b.firstname ? b.firstname.toUpperCase() : '');
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            return response;
          } else {
            return response;
          }
        })
      );
  }

  public getFunnelStage(): Observable<ResponseData<FunnelStageModel>> {
    return this.http.get<ResponseData<FunnelStageModel>>(environment.SERVER_URL + 'crm/funnelStage' + this.getAllByPartner);
  }

  public getFunnelList(): Observable<ResponseData<FunnelModal>> {
    return this.http.get<ResponseData<FunnelModal>>(environment.SERVER_URL + 'crm/funnel/getFunnelByLoggedInUser');
  }

  public getCustomFieldList(): Observable<ResponseData<CustomFieldModel>> {
    return this.http.get<ResponseData<CustomFieldModel>>(environment.SERVER_URL + 'customField' + this.getAllByPartner);
  }

  public getTransportList(): Observable<ResponseData<TransportModel>> {
    return this.http.get<ResponseData<TransportModel>>(environment.SERVER_URL + 'chemical/transport' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  getProductCodes(): Observable<ResponseData<PartCodeModel>> {
    return this.http.get<ResponseData<PartCodeModel>>(environment.SERVER_URL + 'chemical/productCodeConfig' + this.getAllByPartner);
  }

  public getCustomParams(): Observable<ResponseData<CustomParameterModel>> {
    return this.http.get<ResponseData<CustomParameterModel>>(environment.SERVER_URL + 'customParam' + this.getAllByPartner);
  }

  public sortFunction(response) {
    if (response && response.dataList && response.dataList.length !== 0) {
      let data = JSON.parse(JSON.stringify(response.dataList));
      data = data.sort(this.sortAlphabetically);
      response.dataList = data;
      return response;
    } else {
      return response;
    }
  }

  public getDailyProductionModel(): Observable<ResponseData<{ shift: Array<string>, breakDownReason: Array<string> }>> {
    return this.http.get<ResponseData<{ shift: Array<string>, breakDownReason: Array<string> }>>(environment.SERVER_URL + 'chemical/dailyProduction/getDailyProductionModel');
  }

  getItemSpecification(data: VendorCodeRequestResponseModel): Observable<ResponseData<VendorCodeRequestResponseModel>> {
    return this.http.post<ResponseData<VendorCodeRequestResponseModel>>(environment.SERVER_URL + 'chemical/inward/getItemSpecification', data);
  }

  getVendorCode(data: VendorCodeRequestResponseModel): Observable<ResponseData<VendorCodeRequestResponseModel>> {
    return this.http.post<ResponseData<VendorCodeRequestResponseModel>>(environment.SERVER_URL + 'chemical/inward/getVendorCodeForInward', data);
  }

  checkVendorCode(data: VendorCodeRequestResponseModel): Observable<ResponseData<VendorCodeRequestResponseModel>> {
    return this.http.post<ResponseData<VendorCodeRequestResponseModel>>(environment.SERVER_URL + 'chemical/inward/checkVendorCode', data);
  }

  getCodeForVendor(partyId: number): Observable<ResponseData<PartyWiseVendorCodeModel>> {
    return this.http.get<ResponseData<PartyWiseVendorCodeModel>>(environment.SERVER_URL + `chemical/inward/getCodeForVendor/${partyId}`);
  }

  public getAuditRequestList(): Observable<ResponseData<ActivityLogReportModel>> {
    return this.http.get<ResponseData<ActivityLogReportModel>>(environment.SERVER_URL + 'chemical/activityLog/getActivityLogReportModel');
  }


  public getPackingList(): Observable<ResponseData<PackingModel>> {
    return this.http.get<ResponseData<PackingModel>>(environment.SERVER_URL + 'chemical/packing' + this.getAllByPartner).pipe(
      map((response) => {
        if (response && response.dataList && response.dataList.length !== 0) {
          response.dataList = response.dataList.sort((a, b) => {
            const textA = (a.packingType ? a.packingType.toUpperCase() : '');
            const textB = (b.packingType ? b.packingType.toUpperCase() : '');
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          return response;
        } else {
          return response;
        }
      })
    );
  }

  getPlanningList(): Observable<ResponseData<PlanningModel>> {
    return this.http.get<ResponseData<PlanningModel>>(environment.SERVER_URL + 'chemical/planning' + this.getAllByPartner)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: PlanningModel[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = (a.planName ? a.planName.toUpperCase() : '');
              const textB = (b.planName ? b.planName.toUpperCase() : '');
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  getLabTestList(): Observable<ResponseData<LabTestModel>> {
    return this.http.get<ResponseData<LabTestModel>>(environment.SERVER_URL + 'chemical/newLabTest' + this.getAllByPartner)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: LabTestModel[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = (a.reportId ? a.reportId.toUpperCase() : '');
              const textB = (b.reportId ? b.reportId.toUpperCase() : '');
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  getCategoryList(): Observable<ResponseData<CategoryModel>> {
    return this.http.get<ResponseData<CategoryModel>>(environment.SERVER_URL + 'erp/category' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      )
  }

  getNewCategoryList(): Observable<ResponseData<NewCategoryModel>> {
    return this.http.get<ResponseData<NewCategoryModel>>(environment.SERVER_URL + 'erp/categoryNew' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        )
  }

  getProductGroup(): Observable<ResponseData<ProductGroupModel>> {
    return this.http.get<ResponseData<ProductGroupModel>>(environment.SERVER_URL + 'erp/productGroup' + this.getAllByPartner) 
      .pipe(
        map((response) => this.sortFunction(response))
      )
  }

  getMaterialGroup(): Observable<ResponseData<ProductGroupModel>> {
    return this.http.get<ResponseData<ProductGroupModel>>(environment.SERVER_URL + 'erp/material' + this.getAllByPartner) 
      .pipe(
        map((response) => this.sortFunction(response))
      )
  }

  getProductCategory(): Observable<ResponseData<ProductCategory>> {
    return this.http.get<ResponseData<ProductCategory>>(environment.SERVER_URL + 'erp/productCategory' )
      .pipe(
        map((response) => this.sortFunction(response))
      )
  }

  getProductBom(): Observable<ResponseData<NewProductModel>> {
    return this.http.get<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom' + this.getAllByPartner);
  }

  getProductBomListByBomType(reqDto): Observable<ResponseData<NewProductModel>> {
    return this.http.post<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom/getByProductBOMType', reqDto);
  }

  getProductClassification(constant): Observable<ResponseData<ProductClassification>> {
    return this.http.get<ResponseData<ProductClassification>>(environment.SERVER_URL + 'erp/productbom/getProductByClassification/' + constant) 
  }

  getProductBomListByBomId(id): Observable<ResponseData<NewProductModel>> {
    return this.http.get<ResponseData<NewProductModel>>(environment.SERVER_URL + 'chemical/item/getProductBomByItemId/' + id)
  }
  getSubProductBomListByBomId(id): Observable<ResponseData<NewProductModel>> {
    return this.http.get<ResponseData<NewProductModel>>(environment.SERVER_URL + 'chemical/purity/getItemBySubMaterial/' + id)
  }
  getLabourProductBomListByBomId(id): Observable<ResponseData<NewProductModel>> {
    return this.http.get<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom/getProductBomByLabour/' + id)
  }
  getFinishProductBomListByBomId(id): Observable<ResponseData<NewProductModel>> {
    return this.http.get<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom/getProductBomByFinish/' + id)
  }

  getFinishItemList(): Observable<ResponseData<FinishItemModel>> {
    return this.http.get<ResponseData<FinishItemModel>>(environment.SERVER_URL + 'chemical/finishItem' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      )
  }

  getFinishType(): Observable<ResponseData<FinishTypeModel>> {
    return this.http.get<ResponseData<FinishTypeModel>>(environment.SERVER_URL + 'erp/finishType/getAllByPartner')
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  getBrandList(): Observable<ResponseData<BrandModel>> {
    return this.http.get<ResponseData<BrandModel>>(environment.SERVER_URL + 'erp/brand' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  getProductBomListByType(reqModel): Observable<ResponseData<NewProductModel>> {
    return this.http.post<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom/getByProductType', reqModel);
  }

  getCustomerSave(reqModel): Observable<ResponseData<PartyModal>> {
    return this.http.post<ResponseData<PartyModal>>(environment.SERVER_URL + 'party/update', reqModel);
  }

  getOnlyStandAloneAndBaseProduct(productMasterType): Observable<ResponseData<NewProductModel>> {
    return this.http.get<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom/getOnlyStandAloneAndBaseProduct/' + productMasterType);
  }

  getMaterialByProductType(reqDto: { productType: number }): Observable<ResponseData<ProductModal>> {
    return this.http.post<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/item/getItemByProductType',reqDto)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  getStandardProductDtoList(): Observable<ResponseData<ProductModal>> {
    return this.http.get<ResponseData<ProductModal>>(environment.SERVER_URL + 'erp/productbom/getAllBaseProduct')
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  getCurrencyList(): Observable<ResponseData<CurrencyTypeModel>> {
    return this.http.get<ResponseData<CurrencyTypeModel>>(environment.SERVER_URL + 'erp/currency' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  getCustomerTypeList(): Observable<ResponseData<CustomerTypeModel>> {
    return this.http.get<ResponseData<CustomerTypeModel>>(environment.SERVER_URL + 'erp/customerType' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  getUomList(): Observable<ResponseData<UomModel>> {
    return this.http.get<ResponseData<UomModel>>(environment.SERVER_URL + 'erp/uom' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }
  
  getNewCategoryTypeList(): Observable<ResponseData<CategoryTypeModel>> {
    return this.http.get<ResponseData<CategoryTypeModel>>(environment.SERVER_URL + 'erp/categoryType' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  getNewCategoryTypeByIdList(id): Observable<ResponseData<NewCategoryModel>> {
    return this.http.get<ResponseData<NewCategoryModel>>(environment.SERVER_URL + 'erp/categoryNew/getCategoryByType/'+ id)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }
  
  getPriceTypeList(): Observable<ResponseData<PriceTypeModel>> {
    return this.http.get<ResponseData<PriceTypeModel>>(environment.SERVER_URL + 'erp/priceType' + this.getAllByPartner)
      .pipe(
        map((response) => this.sortFunction(response))
      );
  }

  getBasicOHList(): Observable<ResponseData<BasicOHModel>> {
    return this.http.get<ResponseData<BasicOHModel>>(environment.SERVER_URL + 'erp/basicOH' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  getGstList(): Observable<ResponseData<GstModel>> {
    return this.http.get<ResponseData<GstModel>>(environment.SERVER_URL + 'erp/gst' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  getRetailList(): Observable<ResponseData<RetailModel>> {
    return this.http.get<ResponseData<RetailModel>>(environment.SERVER_URL + 'erp/retailCost' + this.getAllByPartner)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  // getStandardProductList():  Observable<ResponseData<NewProductModel>> {
  //   return this.http.get<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom/getAllByProductMasterTypeStandard')
  //       .pipe(
  //           map((response) => this.sortFunction(response))
  //       );
  // }


  getStandardProductList(reqDto): Observable<ResponseData<NewProductModel>> {
    return this.http.post<ResponseData<NewProductModel>>(
      environment.SERVER_URL + 'erp/productbom/getAllByProductMasterTypeStandard',
      reqDto
    );
  }

  getModifiedProductList(reqDto):  Observable<ResponseData<NewProductModel>> {
    return this.http.post<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom/getAllByProductMasterTypeModified',reqDto)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }

  getCustomProductList(reqDto):  Observable<ResponseData<NewProductModel>> {
    return this.http.post<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom/getAllByProductMasterTypeCustom',reqDto)
        .pipe(
            map((response) => this.sortFunction(response))
        );
  }
  public getStatusApproveList(reqObj): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'party/approve',reqObj);
  }

  public getStatusRejectList(reqObj): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'party/rejected',reqObj);
  }

  public getStatusChangepassList(reqObj): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'party/changePassword',reqObj);
  }

  public getStatusrepassList(reqObj): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'party/resetPassword',reqObj);
  }

  public getMoveToCustomList(reqObj): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'orderBook/addToOrderBook',reqObj);
  }

  public getProductById(id: number): Observable<ResponseData<NewProductModel>> {
    return this.http.get<ResponseData<NewProductModel>>(environment.SERVER_URL + 'erp/productbom/'+id);
  }

  public getWoodItemList(id: number): Observable<ResponseData<ProductModal>> {
    return this.http.get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/item/getItemByProductCategoryType/' + id);
  }

  public getPlywoodItemList(id: number): Observable<ResponseData<ProductModal>> {
    return this.http.get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/item/getItemByProductCategoryType/' + id);
  }

  public getStoneItemList(id: number): Observable<ResponseData<ProductModal>> {
    return this.http.get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/item/getItemByProductCategoryType/' + id);
  }

  public getPriceTypeListSpecial(): Observable<ResponseData<PriceTypeModel>> {
    return this.http.get<ResponseData<PriceTypeModel>>(environment.SERVER_URL + 'erp/priceType/getAllByPartner');
  }

  getProductListpagination(reqDto): Observable<ResponseData<ProductModal>> {
    return this.http.post<ResponseData<ProductModal>>(
      environment.SERVER_URL + 'chemical/item/getAllByPartner',
      reqDto
    );
  }
}
