import { Component, OnInit, HostListener, ViewChild, ElementRef, Injectable, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {CommonSelectListService} from '../../shared/service/common-select-list.service';
import {ResponseCode} from '../../core/constant/response-code';
import {ToastrService} from 'ngx-toastr';
import {LoginConfigData} from "../../authentication/modal/login-config-data";
import {LocalDataStorageService} from "../../core/service/local-data-storage.service";
import {LoginService} from "../../authentication/login/service/login.service";
import {SystemConfigService} from "../../system-config/service/system-config.service";
import {SystemConfigModel} from "../../system-config/model/system-config-model";
import {AclConfigCheckService} from "../../modules/acl-config/service/acl-config-check.service";
import {ModuleParamGlobConfigService} from "../../core/service/moduleFieldConfig/module-param-glob-config.service";
import {ModuleParamConfigServiceService} from "../../modules/module-param-config/services/module-param-config-service.service";
import {ModuleParamConfigModel} from "../../modules/module-param-config/model/module-param-config-model";
import {environment} from "../../../environments/environment";
import {Title} from "@angular/platform-browser";
import {UserModal} from "../../modules/user/modal/user-modal";
import {ACLService} from "../../shared/AclService/acl.service";



@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit, AfterViewChecked {
  public layoutMode;
  public showFooter: boolean = true;
  public config: PerfectScrollbarConfigInterface = {};
  public isMiniMenu: boolean = false;
  public systemData: Array<SystemConfigModel>;
  isUserProfileAllow: boolean;
  public headerImgUrl = '';
  footerTitle = (environment.FOOTER_TITLE ? environment.FOOTER_TITLE : 'Neofruition');
  footerUrl = (environment.FOOTER_URL ? environment.FOOTER_URL : 'https://www.neofruition.com/');
  userProfile: UserModal;

  constructor(public router: Router,
              public common: CommonSelectListService,
              private aclConfigCheck: AclConfigCheckService,
              public toaster: ToastrService,
              private cdRef: ChangeDetectorRef,
              private localDataStorageService: LocalDataStorageService,
              private loginService: LoginService,
              private aclService: ACLService,
              private moduleParamGlobConfigService : ModuleParamGlobConfigService,
              private moduleParamConfigService : ModuleParamConfigServiceService,
              private sysConfig: SystemConfigService) {
                // this.layoutMode = this.sysConfig.getLayoutMode();
              }

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;
  public orgId: number;

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin1' // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

   ngOnInit() {
    this.initLoggedUser();

    let token : string = this.localDataStorageService.getAuthToken();
if(this.layoutMode === 'b2b' && !token){ return; }

    if(!token){
      this.router.navigate(["/"]);
      return;
    }
    let labelConfigData: Array<LoginConfigData> = this.localDataStorageService.getLabelDataArray();

    if (!labelConfigData) {
      this.loginService.getLabelConfig().subscribe(
          (responseData) =>{
            if(responseData.dataList){
              this.localDataStorageService.setLabelDataArray(responseData.dataList);
            }
          }
      );
    }

    this.setModuleConfig();
    this.setSysConfig();
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/classic']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    // this.isUserProfileAllow = this.aclConfigCheck.isViewAllow('chemical/userProfile');
  }

  setSysConfig() {
    this.systemData = this.sysConfig.getData('sys_config');

    if (!this.systemData) {
       this.sysConfig.getSystemConifgData().subscribe((response) => {
        if (response.responseCode === ResponseCode.OK) {
          if (response && response.dataList && response.dataList.length !== 0) {
            this.sysConfig.setData('sys_config', response.dataList);
            this.setHeaderImage();
          }
        } else {
          this.toaster.error(response.responseMessage, 'ERROR');
        }
      });
    } else {
      this.setHeaderImage();
    }
  }

  initLoggedUser() {
    this.aclService.getLoggedInUser().subscribe(
        (response) => {
          this.userProfile = new UserModal();
          this.userProfile = response.data;
        }
    );
  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  onLogoutClick() {
    localStorage.clear();
    this.localDataStorageService.clearLabelService();
    this.localDataStorageService.clear();
    this.router.navigate(['/']);
  }

  handleFooter(event) {
      this.showFooter = event;
  }

  ngAfterViewChecked() {
      this.cdRef.detectChanges();
  }

  getOrgData() {
    this.common.getOrganizationData().subscribe((response) => {
      if (response.responseCode === ResponseCode.OK) {
        if (response && response.dataList) {
          this.orgId = response.dataList[0].id;
          this.router.navigate(['/module/org-profile/addUpdate/' + this.orgId]);
        } else {
          this.toaster.error('Organization data not found', 'Error');
        }
      }
    });
  }

  async setModuleConfig() {
    let responseData = await this.moduleParamConfigService.getAll(undefined,undefined).toPromise();
    if(responseData.responseCode == ResponseCode.OK){
      this.moduleParamGlobConfigService.setData(responseData.dataList);
    }
  }

  private setHeaderImage() {
    let token : string = this.localDataStorageService.getAuthToken();
    if (token) {
      let imgUrl;
      if (this.sysConfig.sysConfig) {
        if (this.sysConfig.sysConfig.get('HEADER_LOGO')) {
          imgUrl = environment.SERVER_URL.substr(0, environment.SERVER_URL.indexOf('api')) + 'images/' +
              this.sysConfig.sysConfig.get('HEADER_LOGO');
          this.headerImgUrl = imgUrl;
        }
      }
    }
  }
}
