// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const INWARD_MODE_FOUNDARY = 1 // Inward Foundary
const INWARD_MODE_CHEMICAL = 2 // Inward Chemical
const BASE_URL = 'http://cassa.neofruition.co.in/';
export const environment = {
  production: false,
  SERVER_URL : BASE_URL + 'api/v1/',
  AUTH_URL :  BASE_URL + 'login',
  ACL_URL :  BASE_URL + 'api/v1/acl',
  LOGGED_IN_USER_URL : 'staff/getloggedinuser',
  ACL_LOCAL_KEY : 'aclData',
  DOWNLOADIMG : BASE_URL,
  LOGGED_IN_USER_KEY : 'loggedInUser',
  PRINT_HEADER_IMG_URL:  BASE_URL + 'images/header.jpg',
  PRINT_FOOTER_IMG_URL: '',
  DATE_FORMAT: 'dd-MM-yyyy',
  NUMBER_FORMAT: '1.0-2',
  PRINT_HEADER_IMAGE_URL:BASE_URL+'file/get/',
 LAB_REPORT_DOWNLOAD:BASE_URL+'report/',
INWARD_MODE:INWARD_MODE_CHEMICAL ,
DOWNLOAD_GET_FILE:BASE_URL + 'file/get/',
DISPTACH_FINISH_PRODUCT_SUPPORT: true,
FOOTER_TITLE:'Neofruition',
FOOTER_URL:'https://www.neofruition.com/'


};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


