import { Injectable } from '@angular/core';
import {BaseService} from "../../../core/service/base-service";
import {MachineModal} from "../../machine/modal/machine-modal";
import {HttpClient} from "@angular/common/http";
import {AuthUtilsService} from "../../../core/util/auth-utils.service";
import {ModuleParamConfigModel} from "../model/module-param-config-model";

@Injectable({
  providedIn: 'root'
})
export class ModuleParamConfigServiceService extends BaseService<ModuleParamConfigModel> {

  constructor( http: HttpClient,
               authService: AuthUtilsService
  ) {
    super(http, authService);
  }

  getModuleName(): string {
    console.trace();
    return "chemical/formConfig";
  }

  validate(): boolean {
    return false;
  }
}
